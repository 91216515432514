import { FlexibleDocumentStatusResource } from '@/models/status/FlexibleDocumentStatusResource'
import { formatDateForBackend }           from '@/helpers/formatDate'

export class FlexibleDocumentUpdateStatusRequest {
  public status?: string = undefined
  public status_message?: string = undefined
  public notify_user?: number[] = undefined
  public deadline?: Date = undefined
  public add_to_library?: boolean = undefined

  constructor(status?: FlexibleDocumentStatusResource) {
    if (status) {
      this.status = status.value
      this.status_message = status.need_substantiation ? '' : undefined
      this.notify_user = status.need_allocation?.length > 0 ? [] : undefined
      this.deadline = status.need_deadline ? new Date() : undefined
      this.add_to_library = status.add_to_library ?? undefined
    }
  }

  public getRequestData() {
    if (this.deadline) {
      return {
        ...this,
        deadline: formatDateForBackend(this.deadline)
      }
    } else {
      return {...this}
    }
  }
}
