









































import { Vue, Component, Prop, Model } from 'vue-property-decorator'

import { ProjectUserResource } from '@/models/projects/ProjectUserResource'

import UserImage         from '@/components/user/UserImage.vue'
import DropdownInput     from '@/components/inputs/Dropdown.vue'
import CheckboxInput     from '@/components/inputs/Checkbox.vue'
import StatusColor       from '@/components/statuses/StatusColor.vue'
import UserImagesWrapper from '@/components/user/UserImagesWrapper.vue'

@Component({
  components: {
    UserImage,
    StatusColor,
    DropdownInput,
    CheckboxInput,
    UserImagesWrapper
  }
})
export default class UsersDropdown extends Vue {

  @Model('input')
  private readonly value!: string | number | null | Array<string | number | null>

  @Prop(String)
  private readonly placeholder!: string

  @Prop(String)
  private readonly label!: string

  @Prop(String)
  private readonly name!: string

  @Prop(Boolean)
  private readonly disabled!: boolean

  @Prop(Array)
  private readonly errors!: ErrorResponse[]

  @Prop(Boolean)
  private readonly multiple!: boolean

  @Prop({ default: true })
  private readonly canDeselect!: boolean

  @Prop()
  private users!: ProjectUserResource[]

  @Prop()
  private roles?: string[]

  private get options(): SelectItem[] {
    return this.filteredUsers.map(({ name, id }) => ({ label: name, value: id }))
  }

  private hasValue(value: number[] | number): boolean {
    if (this.multiple && typeof value === 'object') {
      return value.length > 0
    } else {
      return !!value
    }
  }

  private getUser(value: number): ProjectUserResource | undefined {
    return this.users.find(({ id }) => value === id)
  }

  private get filteredUsers(): ProjectUserResource[] {
    return this.users
    return this.roles ? this.users.filter((user) => this.roles?.includes(user.project_role)) : this.users
  }

  private getSelectedNames(value: number[] | number): string {
    return this.users.filter(({ id }) => typeof value === 'number' ? id === value : value.indexOf(id) > -1).map(({ name }) => name).join(', ')
  }

  private getSelectedValues(value: number[] | number): ProjectUserResource[] {
    return this.users.filter(({ id }) => typeof value === 'number' ? id === value : value.indexOf(id) > -1)
  }

  private input(value: string | number | null | Array<string | number | null>): void {
    this.$emit('input', value)
  }

}
