



import Vue                 from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class StatusColor extends Vue {
  @Prop({ default: '#ffffff' })
  private readonly color!: string
}
