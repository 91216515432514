







import { Component, Vue, Prop } from 'vue-property-decorator'

import { UserResource }        from '@/models/users/UserResource'
import { AuthUserResource }    from '@/models/users/AuthUserResource'
import { ProjectUserResource } from '@/models/projects/ProjectUserResource'

import UserImage from './UserImage.vue'

@Component({
  components: {
    UserImage
  }
})
export default class UserImagesWrapper extends Vue {

  @Prop()
  private users!: Array<AuthUserResource | ProjectUserResource | UserResource>

  @Prop()
  private inverted!: boolean

  @Prop({ default: 2 })
  private maxShowCount!: number

  private get userCount(): number {
    return this.users.length
  }

  private get filteredUsers(): Array<AuthUserResource | ProjectUserResource | UserResource> {
    return this.users.slice(0, this.maxShowCount)
  }

  private get userNames(): string {
    return this.users.map(({ name }) => name).join(', ')
  }

  private get showHiddenUsersCount(): boolean {
    return this.userCount > this.maxShowCount
  }

  private get hiddenUsersCount(): number {
    return this.userCount - this.maxShowCount
  }

}
