var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DropdownInput',{staticClass:"status-dropdown",attrs:{"placeholder":_vm.placeholder,"options":_vm.options,"multiple":_vm.multiple,"name":_vm.name,"errors":_vm.errors,"disabled":_vm.disabled,"canDeselect":_vm.canDeselect,"label":_vm.label,"value":_vm.value},on:{"input":_vm.input},scopedSlots:_vm._u([{key:"button",fn:function(ref){
var value = ref.value;
var toggle = ref.toggle;
return [(!_vm.hasValue(value))?_c('button',{staticClass:"button small semimid outline right-icon dropdown-selector dropdown-selector-empty",on:{"click":function($event){return toggle()}}},[_vm._v(" Assign to "),_c('addUserIcon')],1):_c('button',{staticClass:"button small semimid outline dropdown-selector dropdown-selector-filled",on:{"click":function($event){return toggle()}}},[_c('UserImagesWrapper',{staticClass:"users-wrapper",attrs:{"users":_vm.getSelectedValues(value)}}),_c('span',{domProps:{"textContent":_vm._s(_vm.getSelectedNames(value))}})],1)]}},{key:"value",fn:function(ref){
var value = ref.value;
return _vm._l((_vm.getSelectedValues(value)),function(status){return _c('span',{key:status.value,staticClass:"value"},[_c('StatusColor',{attrs:{"color":status.color}}),_vm._v(" "+_vm._s(status.label)+" ")],1)})}},{key:"li",fn:function(ref){
var option = ref.option;
return [(_vm.getUser(option.value))?_c('div',{staticClass:"user"},[_c('UserImage',{attrs:{"user":_vm.getUser(option.value)}}),_c('span',{staticClass:"name",domProps:{"textContent":_vm._s(_vm.getUser(option.value).name)}}),_c('span',{staticClass:"role",domProps:{"textContent":_vm._s(_vm.getUser(option.value).project_role)}})],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }