














































import { canBeMoved, getTocIndex } from '@/helpers/FlexibleDocument'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { FlexibleDocumentResource } from '@/models/flexibleDocument/FlexibleDocumentResource'
import { FlexibleDocumentItemCollection } from '@/models/flexibleDocument/FlexibleDocumentItemCollection'

import draggable from 'vuedraggable'
import menuItem from './MenuItem.vue'
import { FlexibleDocumentUpdateItemOrderRequest } from '@/requests/flexibleDocument/FlexibleDocumentUpdateItemOrderRequest'
import { stateModule } from '@/store'

@Component({
 components: {
   draggable,
   menuItem
 },
})
export default class Index extends Vue {

  @Prop()
  private flexibleDocument!: FlexibleDocumentResource | null

  @Prop()
  private isOpen!: boolean

  private indexTooltip: string = `<strong>Index</strong> The index holds all components of your flexible document. You can use it to quickly navigate to the  section you're looking for. You can also rearrange components by dragging them to the desired position.`

  private get items(): FlexibleDocumentItemCollection[] {
    return this.flexibleDocument?.items ?? []
  }

  private getTocIndex(item: FlexibleDocumentItemCollection, collection: FlexibleDocumentItemCollection[]): string {
    const index = getTocIndex(item, collection)
    return index > -1 ? `${index + 1}.` : ''
  }

  private sort(): void {
    if (this.flexibleDocument) {
      this.flexibleDocument.reorderItems()
      this.patchOrder({ items: this.flexibleDocument.getOrderedObject() })
    }
  }

  private move({ draggedContext }: { draggedContext: { element: FlexibleDocumentItemCollection } }): boolean | -1 | 1 {
    if (this.flexibleDocument) {
      return canBeMoved({
        item: draggedContext.element,
        depth: 0,
      })
    }
    return false
  }

  private async patchOrder(body: Pick<FlexibleDocumentUpdateItemOrderRequest, 'items'>): Promise<void> {
    if (this.flexibleDocument) {
      stateModule.setLoading(true)
      try {
        await this.flexibleDocument.patchOrder(body)
      } catch (e) {
        console.error(e)
      } finally {
        stateModule.setLoading(false)
      }
    }
  }

  private toggle() {
    this.$emit('toggle')
  }

}
