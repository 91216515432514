import { FlexibleDocumentResource } from '@/models/flexibleDocument/FlexibleDocumentResource'

export class FlexibleDocumentUpdateRequest {
  public title: string
  // public end_date: string

  constructor(document?: FlexibleDocumentResource) {
    this.title = document?.title || ''
    // this.end_date = document?.end_date || ''
  }
}
