





































import { stateModule }          from '@/store'
import { ComponentExists }      from '@/plugins/component-exists'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import {
  canBeMoved,
  isFlexibleDocumentItemWithItems,
  isFlexibleDocumentWidgetCollectionResource,
  isFlexibleDocumentChapterCollectionResource,
  isFlexibleDocumentTitlePageCollectionResource,
  isFlexibleDocumentSectionCollectionResource,
  getTocIndex
} from '@/helpers/FlexibleDocument'


import { FlexibleDocumentItemCollection }           from '@/models/flexibleDocument/FlexibleDocumentItemCollection'

import { FlexibleDocumentUpdateItemOrderRequest } from '@/requests/flexibleDocument/FlexibleDocumentUpdateItemOrderRequest'

import draggable  from 'vuedraggable'
import AutoHeight from '@/components/transitions/AutoHeight.vue'

@Component({
  name: 'MenuItem',
  components: {
    draggable,
    AutoHeight
  },
  mixins: [ComponentExists]
})
export default class MenuItem extends Vue {

  @Prop()
  private readonly item!: FlexibleDocumentItemCollection

  @Prop()
  private readonly index!: string | null

  @Prop()
  private readonly depth!: number

  @Prop({ default: false })
  private readonly canEdit!: boolean

  @Prop({ default: false })
  private readonly parentPreventsDrag!: boolean

  private open: boolean = false
  private nonFooterItems: FlexibleDocumentItemCollection[] = []
  private footerItems: FlexibleDocumentItemCollection[] = []

  private get isActive(): boolean {
    return this.$route.params.element_id === this.item.uuid
  }

  private get inActive(): boolean {
    return this.$route.params.element_id || this.$route.params.proposal_id ? this.$route.params.element_id !== this.item.uuid : false
  }

  private getTocIndex(item: FlexibleDocumentItemCollection, collection: FlexibleDocumentItemCollection[]): string | null {
    const childIndex = getTocIndex(item, collection) + 1
    return this.index && childIndex ? `${this.index}${childIndex}.` : ''
  }

  private get depthClass(): string {
    return `depth-${this.depth}`
  }

  private get icon(): string {
    if (isFlexibleDocumentWidgetCollectionResource(this.item)) {
      return `${this.item.icon || ''}Icon`
    } else if (isFlexibleDocumentChapterCollectionResource(this.item)) {
      return 'chapterIcon'
    } else if (isFlexibleDocumentSectionCollectionResource(this.item)) {
      return 'sectionIcon'
    } else if (isFlexibleDocumentTitlePageCollectionResource(this.item)) {
      return 'titlePageIcon'
    }
    return ''
  }

  private get isTitlePage(): boolean {
    return isFlexibleDocumentTitlePageCollectionResource(this.item)
  }

  private get draggableGroup(): string {
    return this.isTitlePage ? 'flexibleDocumentTitlePage' : 'flexibleDocumentItems'
  }

  private get footerGroup(): string {
    return 'flexibleDocumentFooterItems'
  }

  private get canOpen(): boolean {
    return isFlexibleDocumentChapterCollectionResource(this.item) || isFlexibleDocumentTitlePageCollectionResource(this.item) || isFlexibleDocumentSectionCollectionResource(this.item)
  }

  private get isTopLevel(): boolean {
    return this.depth === 0
  }

  private get isWidget(): boolean {
    return isFlexibleDocumentWidgetCollectionResource(this.item)
  }

  private get cantDrag(): boolean {
    return this.parentPreventsDrag
  }

  private get headingText(): string {
    return this.index ? `${this.index} ${this.item.heading}` : `${this.item.heading}`
  }

  @Watch('item.id')
  private onItemChange(): void {
    if (isFlexibleDocumentItemWithItems(this.item)) {
      this.filterItems(this.item.items)
    }
  }

  @Watch('item.items')
  private onItemsChange(): void {
    if (isFlexibleDocumentItemWithItems(this.item)) {
      this.filterItems(this.item.items)
    }
  }

  private created(): void {
    if (isFlexibleDocumentItemWithItems(this.item)) {
      this.filterItems(this.item.items)
    }
  }

  private filterItems(items: FlexibleDocumentItemCollection[]): void {
    this.nonFooterItems = items.filter((item) => isFlexibleDocumentWidgetCollectionResource(item) ? item.widget_type !== 'Footer' : true)
    this.footerItems = items.filter((item) => isFlexibleDocumentWidgetCollectionResource(item) ? item.widget_type === 'Footer' : false)
  }

  private toggleOpen(): void {
    this.open = !this.open
  }

  private sort(): void {
    this.$nextTick(() => {
      if (isFlexibleDocumentChapterCollectionResource(this.item) || isFlexibleDocumentSectionCollectionResource(this.item)) {
        this.$set(this.item, 'items', [...this.nonFooterItems, ...this.footerItems])
        this.item.reorderItems()
        this.patchOrder({ items: this.item.getOrderedObject() })
      }
    })
  }

  private move({ draggedContext }: { draggedContext: { element: FlexibleDocumentItemCollection } }): boolean {
    return canBeMoved({
      item: draggedContext.element,
      depth: this.depth + 1,
    })
  }


  private async patchOrder(body: Pick<FlexibleDocumentUpdateItemOrderRequest, 'items'>): Promise<void> {
    if (isFlexibleDocumentChapterCollectionResource(this.item) || isFlexibleDocumentSectionCollectionResource(this.item)) {
      stateModule.setLoading(true)
      try {
        await this.item.patchOrder(body)
      } catch (e) {
        console.error(e)
      } finally {
        stateModule.setLoading(false)
      }
    }
  }
}
